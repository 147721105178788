import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import './Navbar2.css'
import { BsChatSquareText } from "react-icons/bs";
import { CiStreamOn } from "react-icons/ci";
import { FaBlogger, FaBookmark, FaUserCircle } from "react-icons/fa";
import { ImBooks } from "react-icons/im";
import { TbListDetails } from "react-icons/tb";

import { HiUserCircle } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { GrMenu } from "react-icons/gr";
import { CgProfile } from "react-icons/cg";
import { BsFillGearFill } from "react-icons/bs";
import { IoIosNotifications, IoMdContacts } from "react-icons/io";
import { GrLogout } from "react-icons/gr";
import { BiMessageAltDetail } from "react-icons/bi";
import { MdLogout } from "react-icons/md";
import styled from 'styled-components';

const StyledOffCanvas = styled.aside`
position: fixed;
top: 0;
left: ${props => (props.isOpen ? 0 : '-100%')};
width:300px;
height: 100%;
background-color:#bc0000;
z-index: 1;
color:white;
transition: left 0.5s ease-out;
`;

const StyledOffCanvasOverlay = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
z-index: 0;
display: ${props => (props.isOpen ? 'block' : 'none')};
`;


const Navbar2 = () => {


  const [isOpen, setIsOpen] = useState(false);
  let activeStyle = {

    borderBottom: "2px solid white",

  };
  const navigate = useNavigate();
  return (
    <div className="nav-bg">
    <div className="fix-pro-nav">
      <div className="fle1-nav1">
        <div className="logo-1">
          <img
            onClick={() => navigate("/")}
            className="nav-logo"
            src={require("../../Img/logo.png")}
            alt=""
          />
        </div>
        
          {/* <div className="ul-n"> */}
            {/* <NavLink
              // style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to={"/Home"}
              className="nav-a"
            >
              Home
            </NavLink>
            <NavLink
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            to={"/AboutUs"}
            className="nav-a">About Us</NavLink>
        <NavLink
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            to={"/Course"}
            className="nav-a">Course Details</NavLink>
        <NavLink
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            to={"/Blog"}
            className="nav-a">Study Details</NavLink> */}
        {/* <NavLink 
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
         to={"/Contact"}
         className="nav-a">Contact Us</NavLink>  */}
            {/* <NavLink 
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            to={"Chat"}
         className="nav-a">Chat</NavLink>
         */}
         
        {/* </div> */}

        <div className="nav-bb">
       <div className='log-sig-btn-1'>
       <button onClick={() => navigate("/")} className="login-b">
         HOME
          </button>
          <button onClick={() => navigate("/login")} className="login-b">
            LOG IN
          </button>
          <button onClick={() => navigate("/SignUp")} className="sign-bb">
            SIGN UP
          </button>
       </div>

          {/* mobile-menu */}
          <div className="mobile-menu-nav">
            <button onClick={() => setIsOpen(!isOpen)}>
                  <GrMenu className="open-tab-mob" />
                </button>
            <StyledOffCanvas isOpen={isOpen}>
            <div className="head-mobile-menu1">
                  <ul className="ul-menu-mobile">
                    <li>
                      <div className="mobile-k-m">
                      <button onClick={() => navigate("/login")} className="login-b1">
            LOG IN
          </button>
          <button onClick={() => navigate("/SignUp")} className="login-b1">
            SIGN UP
          </button>
                        <div>
                          <a onClick={() => setIsOpen(false)}>
                            <AiOutlineClose className="close-mobile-menu" />
                          </a>
                        </div>
                      </div>
                    </li>
                    <li>
                    <div className="side-menu-h1">
                        <TbListDetails />
                        <Link to="/Course-details">
                          <span className="link-k">COURSE DETAILS</span>
                        </Link>
                      </div>
                    </li>
                    <li>
                    <div className="side-menu-h1">
                        <FaBookmark />
                        <Link to="/AboutUs2">
                          <span className="link-k">ABOUT US</span>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="side-menu-h1">
                      <FaBlogger/>
                        <Link to="/Blog">
                          <span className="link-k">BLOG</span>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="side-menu-h1">
                      <IoMdContacts/>
                        <Link to="/Contact2">
                          <span className="link-k">CONTACT US</span>
                        </Link>
                      </div>
                    </li>
                  
                  </ul>
                </div>
      </StyledOffCanvas>
      <StyledOffCanvasOverlay isOpen={isOpen} onClick={() => setIsOpen(false)} />
            </div>
        </div>
      </div>
      <hr />
    </div>
  </div>
  )
}

export default Navbar2