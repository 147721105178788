import React, { useContext, useRef, useState } from "react";
import {
  SDivider,
  SLink,
  SLinkContainer,
  SLinkIcon,
  SLinkLabel,
  SLinkNotification,
  SLogo,
  SSearch,
  SSearchIcon,
  SSidebar,
  SSidebarButton,
  STheme,
  SThemeLabel,
  SThemeToggler,
  SToggleThumb,
} from "./styles";
import "./Side.css";
// import { logoSVG } from "../../assets";

import {
  AiOutlineApartment,
  AiOutlineHome,
  AiOutlineLeft,
  AiOutlineSearch,
  AiOutlineSetting,
} from "react-icons/ai";
import { BsBroadcastPin, BsChatSquareText, BsFillCameraVideoFill, BsLayoutSplit } from "react-icons/bs";
import { ImBooks } from "react-icons/im";
import { FaBlogger, FaBookmark } from "react-icons/fa";
import { CiStreamOn } from "react-icons/ci";
import { TbListDetails } from "react-icons/tb";
import { signOut } from "firebase/auth";

import { MdLogout, MdOutlineAnalytics } from "react-icons/md";
import { BsPeople } from "react-icons/bs";

// import { ThemeContext } from "./../../App";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "../../Firebase/Firebase";
import { IoMdContacts } from "react-icons/io";

const Sidebar = () => {

  const navigate = useNavigate();

  const searchRef = useRef(null);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { pathname } = useLocation();

  const searchClickHandler = () => {
    if (!sidebarOpen) {
      setSidebarOpen(true);
      searchRef.current.focus();
    } else {
      // search functionality
    }

    const log = () => {
      // toast("Added Successfully");
      signOut(auth);
      // alert('Login')
      navigate("/login");
    };
  };

  return (
    <SSidebar isOpen={sidebarOpen}>
      <>
        <SSidebarButton
          isOpen={sidebarOpen}
          onClick={() => setSidebarOpen((p) => !p)}
        >
          <BsLayoutSplit />
        </SSidebarButton>
      </>
      <SLogo>{/* <img src={logoSVG} alt="logo" /> */}</SLogo>
      {/* <SSearch
        onClick={searchClickHandler}
        style={!sidebarOpen ? { width: `fit-content` } : {}}
      >
        <SSearchIcon>
          <AiOutlineSearch />
        </SSearchIcon>
        <input
          ref={searchRef}
          placeholder="Search"
          style={!sidebarOpen ? { width: 0, padding: 0 } : {}}
        />
      </SSearch> */}
      <SDivider />
      {linksArray.map(({ icon, label, notification, to }) => (
        <SLinkContainer key={label} isActive={pathname === to}>
          <SLink to={to} style={!sidebarOpen ? { width: `fit-content` } : {}}>
            <SLinkIcon>{icon}</SLinkIcon>
            {sidebarOpen && (
              <>
                <SLinkLabel>{label}</SLinkLabel>
                {/* if notifications are at 0 or null, do not display */}
                {!!notification && (
                  <SLinkNotification>{notification}</SLinkNotification>
                )}
              </>
            )}
          </SLink>
        </SLinkContainer>
      ))}
      <SDivider />
      {secondaryLinksArray.map(({ icon, label }) => (
        <SLinkContainer key={label}>
          <SLink to={"/login"} style={!sidebarOpen ? { width: `fit-content` } : {}}>
            <SLinkIcon>{icon}</SLinkIcon>
            {sidebarOpen && <SLinkLabel>{label}</SLinkLabel>}
          </SLink>
        </SLinkContainer>
      ))}
      <SDivider />
      {/* <STheme>
        {sidebarOpen && <SThemeLabel>Dark Mode</SThemeLabel>}
        {/* <SThemeToggler
                    isActive={theme === "dark"}
                    onClick={() => setTheme((p) => (p === "light" ? "dark" : "light"))}
                >
                    <SToggleThumb style={theme === "dark" ? { right: "1px" } : {}} />
                </SThemeToggler> */}
      {/* </STheme> */}
    </SSidebar>
  );
};

const linksArray = [
  {
    label: "STUDY DETAILS",
    icon: <ImBooks />,
    to: "/Modules",
    notification: 0,
  },
  {
    label: "FORUMS",
    icon: <BsChatSquareText />,
    to: "/Forums",
    notification: 0,
  },
  {
    label: "LIVE STREAM",
    icon: <CiStreamOn />,
    to: "/LiveStream",
    notification: 0,
  },
  {
    label: "COURSE DETAILS",
    icon: <TbListDetails />,
    to: "/Course-details",
    notification: 0,
  },
  {
    label: "ABOUT US",
    icon: <FaBookmark />,
    to: "/AboutUs2",
    notification: 0,
  },
  {
    label: "BROADCAST",
    icon: <BsBroadcastPin/>,
    to: "/Broadcast",
    notification: 0,
  },
  {
    label: "CONTACT US",
    icon: <IoMdContacts/>,
    to: "/Contact",
    notification: 0,
  },

];
const secondaryLinksArray = [
  {
    label: "Logout",
    icon: <MdLogout />,
    to: "/logout",
    
  },
 
];

export default Sidebar;
