import React, { useState } from "react";
import "./Dashboard.css";
import Sidebar2 from "../../Features/Sidebar/Sidebar2";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { MdPhonelinkRing } from "react-icons/md";
const Dashboard = () => {

  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };
  const [toggleState2, setToggleState2] = useState(1);
  const toggleTab2 = (index) => {
    setToggleState2(index);
  };
  const [toggleState3, setToggleState3] = useState(1);
  const toggleTab3 = (index) => {
    setToggleState3(index);
  };
  return (
    <div className="dashboard-bg">
      <div className="body-fix-h">
        <div className="wrapper ">
          <div className="side-dash1">
            <div className="sidebar-home-d">
              <Sidebar2 />
            </div>
            <div className="main-y head-dash">
        
            

              <div className="dash-a1">
              <div className="dash-a2">
                  <div className="dash-logo-1">
                    <img src={require("../../Img/home-bg-1.png")} alt="" />
                  </div>
                  {/* <div>
                    <h1 className="dash-h1">Coaching Results</h1>
                    <p className="dash-p1">In Last Two Years Gold,Silver,Bronze</p>
                  </div> */}
                </div>
                <div className="dash-a16">

                <div className="dash-a15">
                <div className="dash-a6">
                <h1>
                  Intro Video 
                </h1>
               

                {/* Tabs */}
            <div className="dash-tabs">
                <Tabs>
    <TabList>
      <div className="dash-7">
      <Tab
     
      >
        <button
          onClick={() => toggleTab(1)}
        className={toggleState === 1 ? "dash-btn-1" : "dash-btn1"}
       >English</button>
        </Tab>
      <Tab
          
      ><button
      onClick={() => toggleTab(2)}
      className={toggleState === 2 ? "dash-btn-2" : "dash-btn2"}
    >Hindi</button></Tab>
      </div>
    </TabList>
                {/* Intro Video English */}

    <TabPanel>
    <div className="dash-a5">
                  <h1 className="dash-h2">Intro Video English</h1>
                <div className="dash-a4">
                <div className="dash-a3">
                <iframe
                className="i-f-dash-1"
                width="560" height="315" src="https://www.youtube.com/embed/wm3lp9bC5Kc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                </div>

                </div>
    </TabPanel>
                {/* Intro Video Hindi */}

    <TabPanel>
    <div className="dash-a5">
                  <h1 className="dash-h2">Intro Video Hindi</h1>
                <div className="dash-a4">
                <div className="dash-a3">
                <iframe
                className="i-f-dash-1"
                width="560" height="315" src="https://www.youtube.com/embed/wm3lp9bC5Kc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                </div>

                </div>
    </TabPanel>
  </Tabs>
                </div>
{/* Key Features*/}
<h1 className="dash-h12">Key Features</h1>
<div 
    
    >
      <div class="grid">
      <ul id="hexGrid">
        <li class="hex">
          <div class="hexIn">
            <a class="hexLink" >
            <div class='img' ></div>
           
              <p id="demo2">MOST ELABORATED CONTEST PREP COURSE EVER.</p>
            </a>
          </div>
        </li>
          
        <li class="hex">
          <div class="hexIn">
            <a class="hexLink" >
            <div class='img' ></div>
              <p id="demo2">EVERY ASPECT COVERED FOR FEMALE BODY BUILDING.</p>
            </a>
          </div>
        </li>
        <li class="hex">
          <div class="hexIn">
            <a class="hexLink" >
            <div class='img'></div>
              <p id="demo2">SIMPLE & EASY TO GRASP.</p>
            </a>
          </div>
        </li>
        <li class="hex">
          <div class="hexIn">
            <a class="hexLink" >
            <div class='img' ></div>
              <p id="demo2">PURE APPLIACTION IN FIELD.</p>
            </a>
          </div>
        </li>
        <li class="hex">
          <div class="hexIn">
            <a class="hexLink" >
            <div class='img' ></div>
              <p id="demo2">SEPRATE ENGLISH/HINDI LECTURES</p>
            </a>
          </div>
        </li>
        <li class="hex">
          <div class="hexIn">
            <a class="hexLink" >
            <div class='img' ></div>
              <p id="demo3">ACCESS TO LENGENDARY HEAVY DUTY COACHING SOFTWARE AFTER COMPLETION .</p>
            </a>
          </div>
        </li>
        <li class="hex">
          <div class="hexIn">
            <a class="hexLink" >
            <div class='img' ></div>
              <p id="demo2">FREE COURSE UPDATES.</p>
            </a>
          </div>
        </li>
        <li class="hex">
          <div class="hexIn">
            <a class="hexLink" >
            <div class='img' ></div>
              <p id="demo2">INTERACTIVE FORUM SECTION.</p>
            </a>
          </div>
        </li>
        <li class="hex">
          <div class="hexIn">
            <a class="hexLink">
            <div class='img' ></div>
              <p id="demo2">PRIVATE LIVE & PODCAST.</p>
            </a>
          </div>
        </li>
        
       <li class="hex">
          <div class="hexIn">
            <a class="hexLink" >
            <div class='img'></div>
              <p id="demo2">CASE STUDIES.</p>
            </a>
          </div>
        </li>
          <li class="hex">
          <div class="hexIn">
            <a class="hexLink" >
            <div class='img' ></div>
              <p id="demo2">LIFE TIME ACCESSS.</p>
            </a>
          </div>
        </li>
            <li class="hex">
          <div class="hexIn">
            <a class="hexLink" >
            <div class='img'></div>
              <p id="demo2">Some sample text about the article this hexagon leads to</p>
            </a>
          </div>
        </li>
          <li class="hex">
          <div class="hexIn">
            <a class="hexLink" >
            <div class='img' ></div>
              <p id="demo2">Some sample text about the article this hexagon leads to</p>
            </a>
          </div>
        </li>
          <li class="hex">
          <div class="hexIn">
            <a class="hexLink" >
            <div class='img'></div>
              {/* <h1 id="demo1">This is a title</h1> */}
              <p id="demo2">Some sample text about the article this hexagon leads to</p>
            </a>
          </div>
        </li>
      </ul>
        </div>
  
  
  
  
      </div>


{/*  */}

                {/* Pricing cards */}
                <div >
                <h1 className="dash-h8">Fee Details</h1>

                <Tabs>
    <TabList>
      <div className="dash-7">
      <Tab
    
      >
        <button
          onClick={() => toggleTab2(1)}
        className={toggleState2 === 1 ? "dash-btn-1" : "dash-btn1"}
       >INR</button>
        </Tab>
      <Tab
          
      ><button
      onClick={() => toggleTab2(2)}
      className={toggleState2 === 2 ? "dash-btn-2" : "dash-btn2"}
    >USD</button></Tab>
      </div>
    </TabList>
                {/* Intro Video English */}

    <TabPanel>
    <div className="dash-a5">
    <h1 className="dash-h11">INR FEE DETAILS</h1>

    <Tabs>
    <TabList>
      <div className="dash-7">
      <Tab
     
      >
        <button
          onClick={() => toggleTab3(1)}
        className={toggleState3 === 1 ? "dash-btn-1" : "dash-btn1"}
       >OTP</button>
        </Tab>
      <Tab
          
      ><button
      onClick={() => toggleTab3(2)}
      className={toggleState3 === 2 ? "dash-btn-2" : "dash-btn2"}
    >EMI</button></Tab>
      </div>
    </TabList>
                {/* Intro Video English */}

    <TabPanel>
    <div className="dash-a5">
    <h1 className="dash-h11">ONE TIME Payment </h1>

                
   <div class="wrapper1">
 
     
 
     <div class="table-1 Ultimate">
         <div class="price-section-1">
             <div class="price-area-1">
                 <div class="inner-area-1">
                     <span class="dash-text">
                        &#8377;
                     </span>
                     <span class="price">50000
                     </span>
                 </div>
             </div>
         </div>
         <div class="package-name">
             
         </div>
         <div class="features">
             <li>
                 <span class="list-name">All Existing Template</span>
                 <span class="icon check"><i class="fas fa-check-circle"></i></span>
             </li>
             <li>
                 <span class="list-name">100% Responsive Design</span>
                 <span class="icon check"><i class="fas fa-check-circle"></i></span>
             </li>
             <li>
                 <span class="list-name">Credit Remove Permission</span>
                 <span class="icon check"><i class="fas fa-check-circle"></i></span>
             </li>
             <li>
                 <span class="list-name">Lifetime Template Updates</span>
                 <span class="icon check"><i class="fas fa-check-circle"></i></span>
             </li>
             <div class="btn"><button>Purchase</button></div>
         </div>
     </div>
  
     </div>


                </div>
    </TabPanel>
      

    <TabPanel>
    
        <div>

    <div class="wrapper1">
       <div className="dash-a5">
                  <h1 className="dash-h10">EMI details</h1>
                  <div class="table-3 Ultimate">
         <div class="price-section-3">
             <div class="price-area-3">
                 <div class="inner-area-3">
                     <span class="dash-text">
                     &#8377;
                     </span>
                     <span class="price-3dash">50000
                     </span>
                 </div>
             </div>
         </div>
         <div class="package-name-3">
             
         </div>
         <div class="features-3">
             <li>
                 <span class="list-name">All Existing Template</span>
                 <span class="icon check"><i class="fas fa-check-circle"></i></span>
             </li>
             <li>
                 <span class="list-name">100% Responsive Design</span>
                 <span class="icon check"><i class="fas fa-check-circle"></i></span>
             </li>
             <li>
                 <span class="list-name">Credit Remove Permission</span>
                 <span class="icon check"><i class="fas fa-check-circle"></i></span>
             </li>
             <li>
                 <span class="list-name">Lifetime Template Updates</span>
                 <span class="icon check"><i class="fas fa-check-circle"></i></span>
             </li>
             <div class="btn"><button>Purchase</button></div>
         </div>
     </div>

                </div>
                </div>


                {/*  */}



                <div class="row example-split">
        <div class="col-md-12 example-title">
            <h2>EMI DETAILS</h2>
            {/* <p>Small devices (tablets, 768px and up)</p> */}
        </div>
        <div class="col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2">
            <ul class="timeline timeline-split">
                <li class="timeline-item">
                    <div class="timeline-info">
                        <span>March 12, 2016</span>
                    </div>
                    <div class="timeline-marker"></div>
                    <div class="timeline-content">
                        <h3 class="timeline-title">Event Title</h3>
                        <p>Nullam vel sem. Nullam vel sem. Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Donec orci lectus, aliquam ut, faucibus non, euismod id, nulla. Donec vitae sapien ut libero venenatis faucibus. ullam dictum felis
                            eu pede mollis pretium. Pellentesque ut neque.</p>
                    </div>
                </li>
                <li class="timeline-item">
                    <div class="timeline-info">
                        <span>March 23, 2016</span>
                    </div>
                    <div class="timeline-marker"></div>
                    <div class="timeline-content">
                        <h3 class="timeline-title">Event Title</h3>
                        <p>Nullam vel sem. Nullam vel sem. Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Donec orci lectus, aliquam ut, faucibus non, euismod id, nulla. Donec vitae sapien ut libero venenatis faucibus. ullam dictum felis
                            eu pede mollis pretium. Pellentesque ut neque. </p>
                    </div>
                </li>
            
                <li class="timeline-item">
                    <div class="timeline-info">
                        <span>April 02, 2016</span>
                    </div>
                    <div class="timeline-marker"></div>
                    <div class="timeline-content">
                        <h3 class="timeline-title">Event Title</h3>
                        <p>Nullam vel sem. Nullam vel sem. Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Donec orci lectus, aliquam ut, faucibus non, euismod id, nulla. Donec vitae sapien ut libero venenatis faucibus. ullam dictum felis
                            eu pede mollis pretium. Pellentesque ut neque. </p>
                    </div>
                </li>
                <li class="timeline-item">
                    <div class="timeline-info">
                        <span>April 28, 2016</span>
                    </div>
                    <div class="timeline-marker"></div>
                    <div class="timeline-content">
                        <h3 class="timeline-title">Event Title</h3>
                        <p>Nullam vel sem. Nullam vel sem. Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Donec orci lectus, aliquam ut, faucibus non, euismod id, nulla. Donec vitae sapien ut libero venenatis faucibus. ullam dictum felis
                            eu pede mollis pretium. Pellentesque ut neque. </p>
                    </div>
                </li>
                <li class="timeline-item">
                    <div class="timeline-info">
                        <span>April 28, 2016</span>
                    </div>
                    <div class="timeline-marker"></div>
                    <div class="timeline-content">
                        <h3 class="timeline-title">Event Title</h3>
                        <p>Nullam vel sem. Nullam vel sem. Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Donec orci lectus, aliquam ut, faucibus non, euismod id, nulla. Donec vitae sapien ut libero venenatis faucibus. ullam dictum felis
                            eu pede mollis pretium. Pellentesque ut neque. </p>
                    </div>
                </li>
                <li class="timeline-item">
                    <div class="timeline-info">
                        <span>April 28, 2016</span>
                    </div>
                    <div class="timeline-marker"></div>
                    <div class="timeline-content">
                        <h3 class="timeline-title">Event Title</h3>
                        <p>Nullam vel sem. Nullam vel sem. Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Donec orci lectus, aliquam ut, faucibus non, euismod id, nulla. Donec vitae sapien ut libero venenatis faucibus. ullam dictum felis
                            eu pede mollis pretium. Pellentesque ut neque. </p>
                    </div>
                </li>
                <li class="timeline-item">
                    <div class="timeline-info">
                        <span>April 28, 2016</span>
                    </div>
                    <div class="timeline-marker"></div>
                    <div class="timeline-content">
                        <h3 class="timeline-title">Event Title</h3>
                        <p>Nullam vel sem. Nullam vel sem. Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Donec orci lectus, aliquam ut, faucibus non, euismod id, nulla. Donec vitae sapien ut libero venenatis faucibus. ullam dictum felis
                            eu pede mollis pretium. Pellentesque ut neque. </p>
                    </div>
                </li>
                <li class="timeline-item">
                    <div class="timeline-info">
                        <span>April 28, 2016</span>
                    </div>
                    <div class="timeline-marker"></div>
                    <div class="timeline-content">
                        <h3 class="timeline-title">Event Title</h3>
                        <p>Nullam vel sem. Nullam vel sem. Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Donec orci lectus, aliquam ut, faucibus non, euismod id, nulla. Donec vitae sapien ut libero venenatis faucibus. ullam dictum felis
                            eu pede mollis pretium. Pellentesque ut neque. </p>
                    </div>
                </li>
                <li class="timeline-item">
                    <div class="timeline-info">
                        <span>April 28, 2016</span>
                    </div>
                    <div class="timeline-marker"></div>
                    <div class="timeline-content">
                        <h3 class="timeline-title">Event Title</h3>
                        <p>Nullam vel sem. Nullam vel sem. Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Donec orci lectus, aliquam ut, faucibus non, euismod id, nulla. Donec vitae sapien ut libero venenatis faucibus. ullam dictum felis
                            eu pede mollis pretium. Pellentesque ut neque. </p>
                    </div>
                </li>
              

            </ul>
        </div>
    </div>
        </div>


    </TabPanel>
  </Tabs>
                



                </div>
    </TabPanel>
        

    <TabPanel>
   <div class="wrapper1">

    <div className="dash-a5">
                  <h1 className="dash-h10">USD FEE DETAILS</h1>
                  <div class="table-2 Ultimate">
         <div class="price-section-2">
             <div class="price-area-2">
                 <div class="inner-area-2">
                     <span class="dash-text">
                 $
                     </span>
                     <span class="price-2dash">750
                     </span>
                 </div>
             </div>
         </div>
         <div class="package-name-2">
             
         </div>
         <div class="features-2">
             <li>
                 <span class="list-name">All Existing Template</span>
                 <span class="icon check"><i class="fas fa-check-circle"></i></span>
             </li>
             <li>
                 <span class="list-name">100% Responsive Design</span>
                 <span class="icon check"><i class="fas fa-check-circle"></i></span>
             </li>
             <li>
                 <span class="list-name">Credit Remove Permission</span>
                 <span class="icon check"><i class="fas fa-check-circle"></i></span>
             </li>
             <li>
                 <span class="list-name">Lifetime Template Updates</span>
                 <span class="icon check"><i class="fas fa-check-circle"></i></span>
             </li>
             <div class="btn"><button>Purchase</button></div>
         </div>
     </div>

                </div>
                </div>

    </TabPanel>
  </Tabs>
  </div>

 

              </div>
              </div>
              </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
