import React from "react";
import "./Blog.css";
import Slider1 from "../../Features/Sidebar/Sidebar1";
import { useNavigate } from "react-router-dom";
const Vlog = () => {
    const navigate = useNavigate ();
  return (
    <div className="bg-blog body-fix-h">
      <div className="blog-flex-slider-data wrapper">
        <div>
          <Slider1 />
        </div>
        <div className="blog-head-data main-y">
          <div className="data-blog1">
            <div className="head-gap-blog">
              <div className="blog-content1">
                <div className="head-h-b">
                  <h1
                  onClick={ ()=> navigate('/Broadcast/Episodes')}
                  >Episode 1: 2022 Olympia Review</h1>
                </div>
                <div className="head-h-p">
                  <p>
                    Coming Soon
                  </p>
                </div>
              </div>
          
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vlog;
