import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Aboutus from "./Components/Pages/Aboutus/Aboutus";
import Course from "./Components/Pages/Course/Course";
import Course2 from "./Components/Pages/Course/Course2";
import Home from "./Components/Pages/Home/Home";

import Studydetails from "./Components/Pages/Studydetails/Studydetails";
import Contact from "./Components/Pages/Contact/Contact";
import Login from "./Components/Pages/Login/Login";
import Signup from "./Components/Pages/Signup/Signup";
import ChatApp from "./Components/Features/ChatApp/ChatApp";

import Forums from "./Components/Pages/Forum/Forums";
import Replydata from "./Components/Pages/Forum/Replydata";
import "./App.css";
import Test from "./Components/Pages/Test/Test";
import Test2 from "./Components/Pages/Test/Test2";

import LiveS from "./Components/Pages/LiveStream/LiveS";
import Episode from "./Components/Pages/Blog/Episode";
import PrivateRoutes from "./Components/Private/PrivateRoute";
import Logout123 from "./Components/Features/LOGOUT/logout";
import Profile from "./Components/Pages/Profile/Profile";
import Account from "./Components/Pages/Account/Account";
import UserPlan from "./Components/Pages/UserPlan/UserPlan";
import Dashboard from "./Components/Pages/Dashboard/Dashboard";

import LocalRoutes from "./Components/Private/Localroute";
import Vlog from "./Components/Pages/Blog/Vlog";
import Blog from "./Components/Pages/Blog/Blog";
import Aboutus2 from "./Components/Pages/Aboutus/Aboutus2";
import Contact2 from "./Components/Pages/Contact/Contact2";
import Modules from "./Components/Pages/Studydetails/Modules";
import Lessons from "./Components/Pages/Studydetails/Lessons";
import toast, { Toaster } from "react-hot-toast";

const App = () => {
  return (
    <>
      <Router>
        <Toaster />
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/Home" element={<Home />} />
            <Route path="/Modules/Lessons" element={<Studydetails />} />

            <Route path="/Chat" element={<ChatApp />} />
            <Route path="/Forums" element={<Forums />} />
            <Route path="/Reply" element={<Replydata />} />
            <Route path="/testing" element={<Test />} />
            <Route path="/test" element={<Test2 />} />
            <Route path="/AboutUs" element={<Aboutus />} />
            <Route path="/LiveStream" element={<LiveS />} />
            <Route
              path="/Broadcast"
              element={<Vlog />}
            />
            <Route path="/Broadcast/Episodes" element={<Episode />} />
            <Route path="/User" element={<Profile />} />
            <Route path="/UserAccount" element={<Account />} />
            <Route path="/UserPlan" element={<UserPlan />} />
            <Route path="/Course" element={<Course />} />

            <Route path="/Contact" element={<Contact />} />
            <Route path="/Modules" element={<Modules />} />
            {/* <Route path="/Modules/Lessons" element={<Lessons/>} /> */}
          </Route>
          {/* <Navbar2/> */}

          <Route element={<LocalRoutes />}>
            <Route path="/login" element={<Login />} />
            <Route path="/SignUp" element={<Signup />} />
            <Route path="/Blog" element={<Blog />} />
            <Route path="/Course-details" element={<Course2 />} />

            <Route path="/" element={<Dashboard />} />
            <Route path="/AboutUs2" element={<Aboutus2 />} />

            <Route path="/Contact2" element={<Contact2 />} />
          </Route>
          <Route path="/logout" element={<Logout123 />} />

          {/* <Route path="/Sidebar" element={<Sidebar1/>} /> */}
        </Routes>

        {/* <Footer /> */}
      </Router>
    </>
  );
};

export default App;
