import React from 'react'
import './Test.scss'
import Background from '../../Img/user.jpg'
const Test = () => {
  return (
    <div 
    
    style={{padding:'100px 0px'}}>
    <div class="grid">
    <ul id="hexGrid">
      <li class="hex">
        <div class="hexIn">
          <a class="hexLink" >
          <div class='img' ></div>
         
            <p id="demo2">MOST ELABORATED CONTEST PREP COURSE EVER.</p>
          </a>
        </div>
      </li>
        
      <li class="hex">
        <div class="hexIn">
          <a class="hexLink" >
          <div class='img' ></div>
            <p id="demo2">EVERY ASPECT COVERED FOR FEMALE BODY BUILDING.</p>
          </a>
        </div>
      </li>
      <li class="hex">
        <div class="hexIn">
          <a class="hexLink" >
          <div class='img'></div>
            <p id="demo2">SIMPLE & EASY TO GRASP.</p>
          </a>
        </div>
      </li>
      <li class="hex">
        <div class="hexIn">
          <a class="hexLink" >
          <div class='img' ></div>
            <p id="demo2">PURE APPLIACTION IN FIELD.</p>
          </a>
        </div>
      </li>
      <li class="hex">
        <div class="hexIn">
          <a class="hexLink" >
          <div class='img' ></div>
            <p id="demo2">SEPRATE ENGLISH/HINDI LECTURES</p>
          </a>
        </div>
      </li>
      <li class="hex">
        <div class="hexIn">
          <a class="hexLink" >
          <div class='img' ></div>
            <p id="demo2">ACCESS TO LENGENDARY HEAVY DUTY COACHING SOFTWARE AFTER COMPLETION .</p>
          </a>
        </div>
      </li>
      <li class="hex">
        <div class="hexIn">
          <a class="hexLink" >
          <div class='img' ></div>
            <p id="demo2">FREE COURSE UPDATES.</p>
          </a>
        </div>
      </li>
      <li class="hex">
        <div class="hexIn">
          <a class="hexLink" >
          <div class='img' ></div>
            <p id="demo2">INTERACTIVE FORUM SECTION.</p>
          </a>
        </div>
      </li>
      <li class="hex">
        <div class="hexIn">
          <a class="hexLink">
          <div class='img' ></div>
            <p id="demo2">PRIVATE LIVE & PODCAST.</p>
          </a>
        </div>
      </li>
      
     <li class="hex">
        <div class="hexIn">
          <a class="hexLink" >
          <div class='img'></div>
            <p id="demo2">CASE STUDIES.</p>
          </a>
        </div>
      </li>
        <li class="hex">
        <div class="hexIn">
          <a class="hexLink" >
          <div class='img' ></div>
            <p id="demo2">LIFE TIME ACCESSS.</p>
          </a>
        </div>
      </li>
          <li class="hex">
        <div class="hexIn">
          <a class="hexLink" >
          <div class='img'></div>
            <p id="demo2">Some sample text about the article this hexagon leads to</p>
          </a>
        </div>
      </li>
        <li class="hex">
        <div class="hexIn">
          <a class="hexLink" >
          <div class='img' ></div>
            <p id="demo2">Some sample text about the article this hexagon leads to</p>
          </a>
        </div>
      </li>
        <li class="hex">
        <div class="hexIn">
          <a class="hexLink" >
          <div class='img'></div>
            <h1 id="demo1">This is a title</h1>
            <p id="demo2">Some sample text about the article this hexagon leads to</p>
          </a>
        </div>
      </li>
    </ul>
      </div>




    </div>
  )
}

export default Test